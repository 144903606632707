import React, { useState, useRef } from "react";
import "./ProtestScreen.css";
import {
  MapContainer,
  TileLayer,
  Circle,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import { Input } from "antd";
import {
  GoogleMap,
  Autocomplete,
  useJsApiLoader,
} from "@react-google-maps/api";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea, Typography } from "@mui/material";
import {
  AddressAutofill,
  SearchBox,
  useAddressAutofillCore,
  useSearchBoxCore,
  useSearchSession,
} from "@mapbox/search-js-react";
import { SearchSession } from "@mapbox/search-js-core";
import "leaflet/dist/leaflet.css";
import {
  getLocationSuggestions,
  retrieveSuggestion,
} from "../utilities/mapboxAPIs";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import ScrollingText from "../components/ScrollingText";

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
console.log("access token", accessToken);

mapboxgl.accessToken = accessToken;
var currentMarker = null;
// Dummy data for protests
const protests = [
  // Existing protests
  {
    id: 1,
    location: "London",
    startDate: "2024-08-01",
    endDate: "2024-08-10",
    region: "London",
    details: "Climate change rally at Trafalgar Square.",
    streetsAtRisk: [
      "Oxford Street",
      "Piccadilly Circus",
      "Baker Street",
      "Regent Street",
    ],
  },
  {
    id: 2,
    location: "Manchester",
    startDate: "2024-08-09",
    endDate: "2024-08-15",
    region: "North West",
    details: "Workers rights protest at Albert Square.",
    streetsAtRisk: ["Deansgate", "Market Street", "Oxford Road", "King Street"],
  },
  {
    id: 3,
    location: "Birmingham",
    startDate: "2024-08-05",
    endDate: "2024-08-12",
    region: "West Midlands",
    details: "Education reform protest at Victoria Square.",
    streetsAtRisk: [
      "New Street",
      "Corporation Street",
      "Broad Street",
      "Colmore Row",
    ],
  },
  {
    id: 4,
    location: "Leeds",
    startDate: "2024-08-11",
    endDate: "2024-08-14",
    region: "Yorkshire",
    details: "Healthcare access protest at Millennium Square.",
    streetsAtRisk: ["Briggate", "The Headrow", "Vicar Lane", "Albion Street"],
  },
  {
    id: 5,
    location: "Liverpool",
    startDate: "2024-08-12",
    endDate: "2024-08-18",
    region: "North West",
    details: "Environmental protection protest at St. George's Hall.",
    streetsAtRisk: [
      "Bold Street",
      "Castle Street",
      "Renshaw Street",
      "Victoria Street",
    ],
  },
  // Additional protests
  {
    id: 6,
    location: "Bristol",
    startDate: "2024-08-15",
    endDate: "2024-08-20",
    region: "South West",
    details: "Housing rights protest at College Green.",
    streetsAtRisk: [
      "Park Street",
      "Whiteladies Road",
      "Stokes Croft",
      "The Triangle",
    ],
  },
  {
    id: 7,
    location: "Glasgow",
    startDate: "2024-08-20",
    endDate: "2024-08-25",
    region: "Scotland",
    details: "Anti-racism protest at George Square.",
    streetsAtRisk: [
      "Buchanan Street",
      "Sauchiehall Street",
      "Argyle Street",
      "Hope Street",
    ],
  },
  {
    id: 8,
    location: "Edinburgh",
    startDate: "2024-08-25",
    endDate: "2024-08-30",
    region: "Scotland",
    details: "Climate action protest at Princes Street.",
    streetsAtRisk: [
      "Royal Mile",
      "George Street",
      "Lothian Road",
      "Waverley Bridge",
    ],
  },
  {
    id: 9,
    location: "Cardiff",
    startDate: "2024-08-28",
    endDate: "2024-09-02",
    region: "Wales",
    details: "Education protest at City Hall.",
    streetsAtRisk: [
      "Queen Street",
      "St Mary Street",
      "Castle Street",
      "Cowbridge Road",
    ],
  },
];
// Dummy regions for map (in real use, these would be dynamic or from an API)
const protestRegions = {
  London: { center: [51.5074, -0.1278], radius: 10000 },
  "North West": { center: [53.4839, -2.2446], radius: 10000 },
  "West Midlands": { center: [52.4862, -1.8904], radius: 10000 },
  Yorkshire: { center: [53.8008, -1.5491], radius: 10000 },
  "North East": { center: [54.9784, -1.6172], radius: 10000 },
  "South East": { center: [51.2923, 0.4626], radius: 10000 },
  "South West": { center: [50.7221, -3.5276], radius: 10000 },
  East: { center: [52.6095, 1.2801], radius: 10000 },
  Scotland: { center: [56.4907, -4.2026], radius: 10000 },
  Wales: { center: [51.4813, -3.1791], radius: 10000 },
};

// Custom component to handle map zoom and center
const MapHandler = ({ region, center }) => {
  const map = useMap();
  React.useEffect(() => {
    if (region) {
      if (protestRegions[region]) {
        const { center: regionCenter } = protestRegions[region];
        console.log("how the region should look: ", regionCenter);
        map.setView(regionCenter, 9);
      } // Zoom level adjusted for a broader view of the region, smaller number = smaller zoom (further out)
      else {
        if (currentMarker != null) {
          map.removeLayer(currentMarker);
        }
        console.log("region: ", region.center);
        map.setView(region.center, 10);
        currentMarker = L.marker(region.center).addTo(map);
      }
    } else if (center) {
      map.setView(center, 14); // Zoom level for specific location
    }
  }, [region, center, map]);

  return null;
};

const libraries = ["places"]; // Required libraries for Autocomplete

function ProtestScreen() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedProtest, setSelectedProtest] = useState(null);
  const [searchLocation, setSearchLocation] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [locationRisk, setLocationRisk] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const search = new useSearchSession({ accessToken: accessToken });
  const session = new SearchSession(search);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [value, setValue] = useState("");
  const [newsRegion, setNewsRegion] = useState("all");
  const markers = useRef([]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_ACCESS_TOKEN,
    libraries,
  });
  const getAddress = async (lng, lat) => {
    // reverse geocoding
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`
      );
      console.log(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`
      );
      const data = await response.json();
      const locationItem = data.features[0]; //access the lowest level of detail for data [1] woudl go up one, i.e county instead of street
      // response?.data.features[0]?.place_name || "Unknown location";
      console.log("data: ", locationItem);

      // const features = await retrieveSuggestion(
      //   locationItem.properties.mapbox_id,
      //   session
      // );

      // console.log("features collected: ", features);
      // let x = features;

      // We GOt the place, now we gonna process everything here
      setSelectedSuggestion(locationItem);
    } catch (error) {
      console.error("Error getting the address:", error);
    }
  };
  //   const addMarker = (lng, lat) => {
  //     //i.e only one location needed
  //     removeLastMarker();

  //     const marker = new mapboxgl.Marker()
  //       .setLngLat([lng, lat])
  //       .addTo(map.current);

  //     markers.current.push(marker);
  //     console.log(`Marker added at Longitude: ${lng}, Latitude: ${lat}`);
  //   };

  //   const removeLastMarker = () => {
  //     if (markers.current.length > 0) {
  //       const lastMarker = markers.current.pop();
  //       lastMarker.remove();
  //       console.log("Last marker removed");
  //     } else {
  //       console.error("No markers to remove");
  //     }
  //   };

  const handleSelection = async (sug) => {
    console.log("route suggestions: ", sug);
    // console.log("is route analsysi", isRouteAnalysis);

    // console.log("is route analysis", isRouteAnalysis);
    // console.log("suggestion we are saeving", sug);
    // if (marker != null) {
    //   marker.remove();
    // }
    setSuggestions([]);
    // const { features } = await autofill.retrieve(sug, {
    //   sessionToken: session,
    // });
    const features = await retrieveSuggestion(sug.mapbox_id, session);

    console.log("features collected: ", features);
    let x = features;
    setSelectedSuggestion(x);
    // map?.current?.flyTo({
    //   center: features.geometry.coordinates,
    //   zoom: 15,
    // });
    // Marker is placed at analysed location
    //   var marker = new mapboxgl.Marker()
    //     .setLngLat([
    //       features.geometry.coordinates[0], //longitude
    //       features.geometry.coordinates[1], //latitude
    //     ])
    //     .addTo(map.current);
    // console.log("the first value is, ", features[0].geometry.coordinates[0]);
    setValue(sug.name + ", " + sug.place_formatted);
    setSelectedRegion({
      center: [
        features.geometry.coordinates[1],
        features.geometry.coordinates[0],
      ],
      radius: 10000,
    });
  };

  const inputChange = async (v) => {
    // setShowReport(false);
    console.log("value chagnign", v.target.value);
    const val = v.target.value;
    // setValueUnderChange(1);

    setValue(val);
    if (val.length == 0) {
      setSuggestions([]);
      setSelectedSuggestion(null);
    } else if (val.length >= 2) {
      // console.log("val: ", val);
      // const response = await autofill.suggest(val, {
      //   sessionToken: session,
      // });I commented this out
      const response = await getLocationSuggestions(val, session);
      if (response?.length > 0) {
        console.log("all suggestions", response);
        setSuggestions(response);
        // console.log("=============-=========");
        const firstSuggestion = response[0];
        // console.log("first suggestion", firstSuggestion);
        // console.log("=============-=========");
        // console.log

        // const { features } = await autofill.retrieve(response.suggestions, {
        //   sessionToken: session,
        // });
        // setSuggestions(features);
        // console.log("features", features.length);
        console.log("suggestiosn", response);
      }
    } else {
      // console.log("val: ", val);

      // const response = await autofill.suggest(val, {
      //   sessionToken: session,
      // });
      // console.log("hiyaaas---sdfasfd");
      const response = await getLocationSuggestions(val, session);
      // console.log("response before error", response.length);
      if (response?.length > 0) {
        // console.log("all suggestions", response.suggestions);
        setSuggestions(response.suggestions);
        // console.log("=============-=========");
        const firstSuggestion = response[0];
        console.log("first suggestion", firstSuggestion);
        // console.log("=============-=========");

        // const { features } = await autofill.retrieve(firstSuggestion, {
        //   sessionToken: session,
        // });
        // setSuggestions(features[0]);

        // console.log("features", features);
      }
    }
  };
  const filteredProtests = protests.filter(
    (protest) =>
      (protest.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        protest.startDate.includes(searchTerm)) &&
      (!selectedRegion || protest.region === selectedRegion)
  );

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //   const handlePlaceSelect = () => {
  //     if (autocomplete) {
  //       const place = autocomplete.getPlace();
  //       const { lat, lng } = place.geometry.location;
  //       const locationCoords = [lat(), lng()];
  //       setSearchLocation(locationCoords);

  //       // Check if the selected location is within a protest region
  //       let riskFound = null;
  //       Object.keys(protestRegions).forEach((region) => {
  //         if (protestRegions[region] != null) {
  //           const distance = L.latLng(locationCoords).distanceTo(
  //             L.latLng(protestRegions[region].center)
  //           );
  //         }
  //         if (distance <= protestRegions[region].radius) {
  //           riskFound = region;
  //         }
  //       });

  //       setLocationRisk(riskFound);
  //     }
  //   };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  // Define the default icon
  const DefaultIcon = L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
    shadowSize: [41, 41],
  });

  // Set the default icon globally
  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <div className="protest-screen">
      <header
        className="header"
        style={{
          background: "linear-gradient(45deg, #ff0000, #00ff00)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        <h1 style={{ width: "100%", textAlign: "center" }}>
          Ongoing Riots In England
        </h1>
      </header>
      <main className="content">
        <div className="search-filter">
          {/* <div className="suggestionsContainer">
            <div className="inner-container">
              <Input
                allowClear
                style={{ minWidth: 400 }}
                autoComplete={true}
                size="large"
                placeholder="Starting location "
                value={value}
                onChange={inputChange}
              />
            </div>

          
          </div> */}
          <div>
            <Input
              allowClear
              style={{ minWidth: "40%" }}
              autoComplete={true}
              size="large"
              placeholder="Starting location "
              value={value}
              onChange={inputChange}
            />
            <div
              style={{
                position: "absolute",
                left: "1%",
                top: "35%",
                zIndex: 10000,
              }}
            >
              {suggestions?.map((suggestion) => {
                return (
                  <Card
                    sx={{
                      // maxWidth: 345,
                      // alignItems: "center",
                      // maxHeight: 30,
                      textAlign: "left",
                      minHeight: 30,
                      paddingLeft: 1,
                      // paddingLeft: -100,
                      // justifyContent: "center",
                      // alignContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    <CardActionArea onClick={() => handleSelection(suggestion)}>
                      <CardContent>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                          sx={{
                            // alignSelf: "center",
                            // background: "blue",
                            // height: 10,
                            fontSize: 12,
                            fontWeight: "700",
                            marginLeft: -2,
                          }}
                        >
                          {suggestion.name}
                        </Typography>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                          sx={{
                            // alignSelf: "center",
                            // background: "blue",
                            // height: 10,
                            color: "grey",
                            fontSize: 9,
                            fontWeight: "600",
                            marginLeft: -2,
                          }}
                        >
                          {suggestion.place_formatted}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
            </div>
          </div>
          <div>
            <select
              style={{ marginLeft: "15%" }}
              onChange={(e) => setSelectedRegion(e.target.value)}
              value={selectedRegion || ""}
            >
              <option value="">Select Region</option>
              {Object.keys(protestRegions).map((region) => (
                <option key={region} value={region}>
                  {region}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="map-container">
          <MapContainer
            center={[51.5074, -0.1278]}
            zoom={6}
            style={{ height: "600px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapHandler region={selectedRegion} center={searchLocation} />

            {protests.map((protest) => (
              <React.Fragment key={protest.id}>
                <Circle
                  center={protestRegions[protest.region].center}
                  radius={protestRegions[protest.region].radius / 2}
                  color="red"
                  fillOpacity={0.4}
                  stroke={false}
                />
                <Marker position={protestRegions[protest.region].center}>
                  <Popup>
                    <h2>{protest.location}</h2>
                    <p>
                      <strong>Dates:</strong> {protest.startDate} -{" "}
                      {protest.endDate}
                    </p>
                    <p>
                      <strong>Region:</strong> {protest.region}
                    </p>
                    <p>
                      <strong>Details:</strong> {protest.details}
                    </p>
                    <h3>Streets at Risk:</h3>
                    <ul>
                      {protest.streetsAtRisk.map((street, index) => (
                        <li key={index}>{street}</li>
                      ))}
                    </ul>
                  </Popup>
                </Marker>
              </React.Fragment>
            ))}
          </MapContainer>
        </div>
        {locationRisk && (
          <div className="risk-notification">
            <p>
              You are near a protest area in the <strong>{locationRisk}</strong>{" "}
              region. Please be cautious and check local updates.
            </p>
          </div>
        )}
        <div className="latest-news">
          <h2>Latest News Headlines</h2>
          <div className="region-selector">
            <label htmlFor="region">Select Region:</label>
            <select
              id="region"
              onChange={(e) => setNewsRegion(e.target.value)}
              value={newsRegion}
            >
              <option value="all">All</option>
              <option value="north">North</option>
              <option value="south">South</option>
              <option value="east">East</option>
              <option value="west">West</option>
              <option value="london">London</option>
            </select>
          </div>
          <ScrollingText region={newsRegion} />
        </div>
        <div className="contact-info">
          <h2>Sources</h2>
          <p>Powered by Sky news; BBC News; Local Reports</p>
        </div>
        <div className="advice-section">
          <h2>General Advice</h2>
          <p>
            Stay informed and safe during protests. Avoid areas marked as
            dangerous and follow local authorities' guidelines.
          </p>
        </div>
        <button className="scroll-to-top" onClick={handleScrollToTop}>
          Scroll to Top
        </button>
      </main>
    </div>
  );
}

export default ProtestScreen;
