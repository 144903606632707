import React from "react";
import Button from "@mui/material/Button";
import "./HomeScreen.css";
import { useNavigate } from "react-router-dom";

export default function HomeScreen() {
  const navigate = useNavigate();
  // const search = new useSearchSession({ accessToken: accessToken });
  // const session = new SearchSession(search);

  // const searchBoxCore = useSearchBoxCore({ accessToken: accessToken });

  // const getSuggestions = async () => {
  //   console.log("we inside");
  //   const response = await searchBoxCore.suggest("1600 pennsylvania ave nw", {
  //     sessionToken: session,
  //   });
  //   console.log(response);
  // };

  return (
    <div className="container">
      <h1 className="Homepage-Title">Eleos</h1>
      <h3 className="slogan">Dedicated to Keeping You Safe</h3>
      <div className="investor-message">
        <p>We are looking for early adopters who like this idea and would be willing to give constant feedback,ensuring our product is as good as possible. If this sounds like you, please reach out.</p>
      </div>
      <div className="button-container">
        <Button
          onClick={() => navigate("./eleos")}
          sx={{
            marginRight: 5,
            width: 170,
            maxHeight: 40,
            fontSize: 15,
            fontWeight: 600,
          }}
          variant="contained"
          className="button"
        >
          (Beta) Travel
        </Button>
        {/* <Button
          variant="contained"
          className="button"
          sx={{
            // marginRight: 10,
            width: 170,
            maxHeight: 40,
            fontSize: 15,
            fontWeight: 600,
          }}
          onClick={() => navigate("./protests")}
        >
          Riot Areas
        </Button> */}
      </div>
    </div>
  );
}
