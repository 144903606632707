export const getSafetyScore = async (lat, lng) => {
  const url = `https://sharp-lobster-eleos-13d1303c.koyeb.app/getscore/?lat=${lat}&lng=${lng}`;
  //   const url = `https://sharp-lobster-eleos-13d1303c.koyeb.app/`;
  console.log("url being used", url);

  //   console.log("url: ", url);
  const response = await fetch(url);
  console.log("got passed response");

  const json = await response.json();
  console.log("json: ", json);
  return json;

  //   fetch(
  //     `https://sharp-lobster-eleos-13d1303c.koyeb.app/getscore/?lat=${lat}&lng=${lng}`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => console.log("score: ", data));
};

export const getRouteSafetyScore = async (latLngList) => {
  const pointsArray = latLngList;
  const url = "https://sharp-lobster-eleos-13d1303c.koyeb.app/getroutescore/";
  console.log("url being used", url);
  console.log("object sending: ", pointsArray);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pointsArray),
  });
  console.log("called routes analysuis");
  console.log("Da response  was: ", response);
  const responseData = await response.json();
  console.log("response daata was: ", responseData);
  return responseData;
};
