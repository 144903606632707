import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./FeedbackScreen.css";

const FeedbackScreen = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    feedback: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using emailjs
    emailjs
      .send(
        "service_i2hkyvo", // Replace with your EmailJS service ID
        "template_uiv9gam", // Replace with your EmailJS template ID
        formData,
        "6BA81Iu_HeZKMUvKZ" // Replace with your EmailJS user ID
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        // Clear all fields
        setFormData({
          name: "",
          email: "",
          feedback: "",
        });
      })
      .catch((err) => {
        console.error("FAILED...", err);
      });
  };

  return (
    <div className="feedback-container">
      <h2>Feedback</h2>
      <p>
        Your feedback is important to us. Please let us know how we can improve.
      </p>
      <form onSubmit={handleSubmit} className="feedback-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="feedback">Feedback</label>
          <textarea
            id="feedback"
            name="feedback"
            value={formData.feedback}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="send-button">
          Send Feedback
        </button>
      </form>
    </div>
  );
};

export default FeedbackScreen;
