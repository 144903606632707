import React from "react";
import "./ScrollingText.css";

function ScrollingText({ region = "all", shortenedAnimationTime = 40 }) {
  // Define headlines for each region
  const headlines = {
    north: [
      "📰 North: Major Snowstorm Expected!",
      "📢 North: City Council Election Results",
      "🚨 North: Road Closures Due to Ice",
      "⚽ North: Local Football Team Wins Championship",
    ],
    south: [
      "☀️ South: Heatwave Warning Issued",
      "📢 South: New Shopping Mall Opening Soon",
      "🚨 South: Traffic Delays on Highway 10",
      "🎉 South: Annual Beach Festival Announced",
    ],
    east: [
      "🌧️ East: Heavy Rain Causes Flooding",
      "📢 East: Community Center Renovations",
      "🚨 East: Police Investigation Ongoing",
      "🎭 East: Theater District Events This Weekend",
    ],
    west: [
      "🌲 West: Forest Fire Alert",
      "📢 West: Local Business Expo This Week",
      "🚨 West: Power Outages in Rural Areas",
      "🚴 West: Bike Race Coming to Town",
    ],
    london: [
      "🕍 London: New Museum Exhibit Opening",
      "🚇 London: Tube Strike This Weekend",
      "🚨 London: Increased Security in Central London",
      "🎤 London: Major Concert at Wembley",
    ],
    // Add more regions as needed
  };

  // Combine all headlines when "all" is selected
  const allHeadlines = [
    ...headlines.north,
    ...headlines.south,
    ...headlines.east,
    ...headlines.west,
    ...headlines.london,
  ];

  // Select headlines based on the region or all
  const selectedHeadlines = region === "all" ? allHeadlines : headlines[region];

  return (
    <div className="scrolling-container">
      {region === "all" ? (
        <div className="scrolling-text">
          {selectedHeadlines?.map((headline, index) => (
            <span key={index}>{headline}</span>
          ))}
        </div>
      ) : (
        <div className="scrolling-text-faster">
          {selectedHeadlines?.map((headline, index) => (
            <span key={index}>{headline}</span>
          ))}
        </div>
      )}
    </div>
  );
}

export default ScrollingText;
