import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css"; // Importing the new CSS file

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-title">Eleos</div>
      <div className="hamburger" onClick={toggleMenu}>
        &#9776; {/* Unicode character for hamburger menu */}
      </div>
      <ul className={`navbar-links ${isMenuOpen ? "open" : ""}`}>
        <li>
          <Link to="/" onClick={toggleMenu}>Home</Link>
        </li>
        <li>
          <Link to="/contact" onClick={toggleMenu}>Contact</Link>
        </li>
        <li>
          <Link to="/feedback" onClick={toggleMenu}>Feedback</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
