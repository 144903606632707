import React, { useRef, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "./EleosScreen.css";
import top100Films from "../data";
import {
  AddressAutofill,
  SearchBox,
  useAddressAutofillCore,
  useSearchBoxCore,
  useSearchSession,
} from "@mapbox/search-js-react";
import { SearchSession } from "@mapbox/search-js-core";
import { AutoComplete, Input } from "antd";
import { Button, Flex, Tooltip } from "antd";
import {
  MinusCircleTwoTone,
  PlusCircleOutlined,
  PlusCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea, Typography } from "@mui/material";

import "mapbox-gl/dist/mapbox-gl.css";
import { getRouteSafetyScore, getSafetyScore } from "../apis/safetyscore";
import zIndex from "@mui/material/styles/zIndex";
import {
  getLocationSuggestions,
  retrieveSuggestion,
} from "../utilities/mapboxAPIs";
console.log("Hello");
const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
console.log("access token", accessToken);

mapboxgl.accessToken = accessToken;

export default function EleosScreen() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-0.126106);
  const [lat, setLat] = useState(51.501062);
  const [zoom, setZoom] = useState(9);
  const [currentStepInd, setcurrentStepInd] = useState(0);
  const [steps, setSteps] = useState([]);
  const search = new useSearchSession({ accessToken: accessToken });
  const autofill = useAddressAutofillCore({ accessToken: accessToken });
  const session = new SearchSession(search);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [selectedSuggestion2, setSelectedSuggestion2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState(""); //this is the destination varaible when there are two inputs
  const [safetyScore, setSafetyScore] = useState(0.0);
  const [isRouteAnalysis, setIsRouteAnalysis] = useState(false);
  const [valueUnderChange, setValueUnderChange] = useState(1); //this is the value that we are in the process of changing
  const [showReport, setShowReport] = useState(false); //will use this to determine if all processing is done and we should show the report
  const [safetyScores, setSafetyScores] = useState([]);
  const [stepObjects, setStepObjects] = useState([]);
  const [originObj, setOriginObj] = useState(null);
  const [destObj, setDestObj] = useState(null);
  const [isScoreloading, setIsScoreLoading] = useState(false);
  const searchBoxCore = useSearchBoxCore({ accessToken: accessToken });
  const [selectedRouteInd, setSelectedRouteInd] = useState("");
  const [noRoutes, setNoRoutes] = useState(0);
  const [steps2, setSteps2] = useState([]);
  const [steps3, setSteps3] = useState([]);
  const [selectedSteps, setSelectedSteps] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false); //report view
  const markers = useRef([]);

  const getSuggestions = async (val) => {
    console.log("we inside");
    console.log("val", val);
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  useEffect(() => {
    if (selectedRouteInd == 0) {
      setSelectedSteps(steps);
    } else if (selectedRouteInd == 1) {
      setSelectedSteps(steps2);
    } else if (selectedRouteInd == 2) {
      setSelectedSteps(steps3);
    }
  }, [selectedRouteInd]);

  const load_route_safety_scores = async (routeSteps) => {
    // console.log("load routes functions: -------: ");
    // this is where we process the coordinates of the root
    setIsLoading(true);
    const sObjects = [];
    setIsScoreLoading(true);
    console.log("we are setting is score loading");
    let arrayToSend = routeSteps.map((step) => {
      let obj = {
        lat: step.geometry.coordinates[0][1],
        lng: step.geometry.coordinates[0][0],
      };
      return obj;
    });
    var onePointScore = await getSafetyScore(
      arrayToSend[Math.floor(arrayToSend.length / 2)].lat,
      arrayToSend[Math.floor(arrayToSend.length / 2)].lng
    );
    console.log("one point score: ", onePointScore);
    onePointScore = parseInt(onePointScore.score.toFixed(1));
    console.log("array im sending after processing: ", arrayToSend);
    //const newData = await getRouteSafetyScore(arrayToSend);
    // this is where i need to add the code for getting all the scores of coordinates and then
    for (let i = 0; i < routeSteps.length; i++) {
      const step = routeSteps[i];
      // check if step is the middle step

      console.log(
        "instructions im using for obj, ",
        step?.maneuver?.instruction
      );

      let obj = {
        lat: 0,
        lng: 0,
        score: onePointScore,
        instruction: step?.maneuver?.instruction,
      };
      // console.log(
      //   "this is apparently the address: ",
      //   step.maneuver.instruction
      // );

      // const safetyScore = await getSafetyScore(
      //   step.geometry.coordinates[0][1],
      //   step.geometry.coordinates[0][0]
      // ); // Assuming coordinates are [lat, lng]
      obj.lat = arrayToSend[i].lat;
      obj.lng = arrayToSend[i].lng;

      sObjects.push(obj);
    }
    // console.log("step Objects local: ", sObjects);
    setStepObjects(sObjects);
    //step objects is an array of obejcts for a particular route
    //we give this object a route and it takes the latlngs of this route, processes them and returns the latlngs with a score. We then display this score when showing the user the routes
    //we also average these score for the overall route safety
    //what the api shoudl do is that somehow i pass the latlngs of the steps  and then I auto get back sObjects
    setIsScoreLoading(false);
    setIsLoading(false);
  };

  const handlePlusMinusBtnPress = () => {
    //alert here set
    // setValue("");
    setIsRouteAnalysis(!isRouteAnalysis);
    setShowReport(false);
  };

  useEffect(() => {
    console.log("is score loading", isScoreloading);
  }, [isScoreloading]);

  const loadMap = async () => {
    try {
      console.log("origns, ", originObj);
      console.log("dest, ", originObj);

      const response = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/walking/${originObj.geometry.coordinates[0]},${originObj.geometry.coordinates[1]};${destObj.geometry.coordinates[0]},${destObj.geometry.coordinates[1]}?geometries=geojson&alternatives=true&overview=full&steps=true&access_token=${accessToken}`
      );
      console.log(
        "url im using to get routes safety scores: " +
          `https://api.mapbox.com/directions/v5/mapbox/walking/${originObj.geometry.coordinates[0]},${originObj.geometry.coordinates[1]};${destObj.geometry.coordinates[0]},${destObj.geometry.coordinates[1]}?geometries=geojson&alternatives=true&overview=full&steps=true&access_token=${accessToken}`
      );

      console.log("respnse", response);
      const data = await response.json();

      const routes = data.routes;
      console.log("routes", routes);
      const noRoutes = routes.length;
      setNoRoutes(noRoutes);
      const routeSteps = routes[0].legs[0].steps; // Assuming we are only interested in the first route and first leg
      setSteps(routeSteps);
      if (noRoutes == 2) {
        console.log("this is the second route");
        const routeSteps = routes[1].legs[0].steps; // Assuming we are only interested in the first route and first leg
        setSteps2(routeSteps);
        console.log("route steops", routeSteps);
      }
      if (noRoutes == 3) {
        const routeSteps = routes[2].legs[0].steps; // Assuming we are only interested in the first route and first leg
        setSteps3(routeSteps);
        console.log("route steops", routeSteps);
      }

      load_route_safety_scores(routeSteps);

      routes.forEach((route, index) => {
        const routeGeometry = route.geometry;

        map.current.addSource(`route-${index}`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: routeGeometry,
          },
        });

        map.current.addLayer({
          id: `route-${index}`,
          type: "line",
          source: `route-${index}`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be", // Default color for routes 3887be
            "line-width": 8,
            "line-opacity": 0.5,
          },
        });

        const bounds = new mapboxgl.LngLatBounds();
        routeGeometry.coordinates.forEach((coord) => bounds.extend(coord));
        map.current.fitBounds(bounds, { padding: 100 });
      });
    } catch (error) {
      console.error("Error fetching directions:", error);
    }
  };
  const analyseAndDisplayRoutes = async () => {
    console.log("got to displa");
    // map.current.on("load", async () => {
    await loadMap();
    // });
    setShowReport(true);
    ///this is where we do all our processing and calcualtion
    setSafetyScore(6.8);
  };

  const inputChange = async (v) => {
    setShowReport(false);
    console.log("value chagnign", v.target.value);
    const val = v.target.value;
    setValueUnderChange(1);

    setValue(val);
    if (val.length == 0) {
      setSuggestions([]);
      setSelectedSuggestion(null);
    } else if (val.length >= 2) {
      // console.log("val: ", val);
      // const response = await autofill.suggest(val, {
      //   sessionToken: session,
      // });I commented this out
      const response = await getLocationSuggestions(val, session);
      if (response?.length > 0) {
        console.log("all suggestions", response);
        setSuggestions(response);
        // console.log("=============-=========");
        const firstSuggestion = response[0];
        // console.log("first suggestion", firstSuggestion);
        // console.log("=============-=========");
        // console.log

        // const { features } = await autofill.retrieve(response.suggestions, {
        //   sessionToken: session,
        // });
        // setSuggestions(features);
        // console.log("features", features.length);
        console.log("suggestiosn", response);
      }
    } else {
      // console.log("val: ", val);

      // const response = await autofill.suggest(val, {
      //   sessionToken: session,
      // });
      // console.log("hiyaaas---sdfasfd");
      const response = await getLocationSuggestions(val, session);
      // console.log("response before error", response.length);
      if (response?.length > 0) {
        // console.log("all suggestions", response.suggestions);
        setSuggestions(response.suggestions);
        // console.log("=============-=========");
        const firstSuggestion = response[0];
        console.log("first suggestion", firstSuggestion);
        // console.log("=============-=========");

        // const { features } = await autofill.retrieve(firstSuggestion, {
        //   sessionToken: session,
        // });
        // setSuggestions(features[0]);

        // console.log("features", features);
      }
    }
  };

  useEffect(() => {
    console.log("step obkects", stepObjects);
  }, [stepObjects]);

  const inputChangeValue2 = async (v) => {
    setShowReport(false);

    console.log("value chagnign", v.target.value);
    const val = v.target.value;
    setValueUnderChange(2);

    setValue2(val);
    if (val.length == 0) {
      setSuggestions([]);
      setSelectedSuggestion(null);
    } else if (suggestions.length >= 1) {
      // console.log("val: ", val);
      // const response = await autofill.suggest(val, {
      //   sessionToken: session,
      // });
      const response = await getLocationSuggestions(val, session);
      if (response?.length > 0) {
        console.log("all suggestions", response);
        setSuggestions(response);
        // console.log("=============-=========");
        const firstSuggestion = response[0];
        // console.log("first suggestion", firstSuggestion);
        // console.log("=============-=========");
        // console.log

        // const { features } = await autofill.retrieve(response.suggestions, {
        //   sessionToken: session,
        // });
        // setSuggestions(features);
        // console.log("features", features.length);
      }
    } else {
      // console.log("val: ", val);

      // const response = await autofill.suggest(val, {
      //   sessionToken: session,
      // });
      const response = await getLocationSuggestions(val, session);
      if (response?.length > 0) {
        // console.log("all suggestions", response.suggestions);
        setSuggestions(response);
        // console.log("=============-=========");
        const firstSuggestion = response[0];
        console.log("first suggestion", firstSuggestion);
        // console.log("=============-=========");

        // const { features } = await autofill.retrieve(firstSuggestion, {
        //   sessionToken: session,
        // });
        // setSuggestions(features[0]);

        // console.log("features", features);
      }
    }
  };
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      accessToken: "pk.eyJ1IjoidG9iaWJhbWlzYXllIiwiYSI6ImNsc2RudnM5dTBhdmcya3BqbDI1YmEzbHUifQ.PSwE9_644bwbrEGkNptkMg",
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
    map.current.on("click", (e) => {
      const { lng, lat } = e.lngLat;
      console.log(`Longitude: ${lng}, Latitude: ${lat}`);
      console.log("clicked");

      setShowReport(false);
      setValue("");
      setValue2("");
      addMarker(lng, lat);
      getAddress(lng, lat);

      // alert(`Longitude: ${lng}, Latitude: ${lat}`); // added alert for better visibility
    });
  });

  useEffect(() => {
    console.log("hiya");
  }, []);

  const getAddress = async (lng, lat) => {
    // reverse geocoding
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`
      );
      console.log(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`
      );
      const data = await response.json();
      const locationItem = data.features[0]; //access the lowest level of detail for data [1] woudl go up one, i.e county instead of street
      // response?.data.features[0]?.place_name || "Unknown location";
      console.log("data: ", locationItem);
      const score = await getSafetyScore(
        locationItem.geometry.coordinates[1],
        locationItem.geometry.coordinates[0]
      );
      // const features = await retrieveSuggestion(
      //   locationItem.properties.mapbox_id,
      //   session
      // );

      // console.log("features collected: ", features);
      // let x = features;

      // We GOt the place, now we gonna process everything here
      console.log("the socre produced: ", score.score.toFixed(1));
      setSelectedSuggestion(locationItem);
      setOriginObj(locationItem);
      // const features = await retrieveSuggestion(
      //   locationItem.properties.mapbox_id,
      //   session
      // );

      // // setOriginObj(features)
      // console.log(
      //   "features collected from clicked locations: ",
      //   features?.geometry.coordinates
      // );
      // let x = features;
      // setSelectedSuggestion(x);
      setSafetyScore(score.score.toFixed(1));
      setValue(locationItem.place_name); // wghne using geocode v5 we get place_name isntaead
      setShowReport(true);
      console.log("done");
      setIsLoading(false);
      console.log("changed");
    } catch (error) {
      console.error("Error getting the address:", error);
    }
  };

  const addMarker = (lng, lat) => {
    if (!isRouteAnalysis) {
      //i.e only one location needed
      removeLastMarker();
    }
    const marker = new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(map.current);

    markers.current.push(marker);
    console.log(`Marker added at Longitude: ${lng}, Latitude: ${lat}`);
  };

  const removeLastMarker = () => {
    if (markers.current.length > 0) {
      const lastMarker = markers.current.pop();
      lastMarker.remove();
      console.log("Last marker removed");
    } else {
      console.error("No markers to remove");
    }
  };

  useEffect(() => {
    if (selectedSteps.length > 0 && map.current) {
      console.log(
        "the current selected steps we're using after chanig thein index",
        selectedSteps
      );
      selectedSteps.forEach((step, index) => {
        console.log("current step", step);
        console.log("index", index);
        console.log(
          "does it pass the if statement: ",
          map.current.getSource(`step-${index}`)
        );
        if (map.current.getSource(`step-${index}`)) {
          map.current.removeLayer(`step-${index}`);
          map.current.removeSource(`step-${index}`);
        }

        map.current.addSource(`step-${index}`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: step.geometry,
          },
        });

        map.current.addLayer({
          id: `step-${index}`,
          type: "line",
          source: `step-${index}`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": index === currentStepInd ? "#ff0000" : "green", // Red for the current step, blue for others
            "line-width": 8,
            "line-opacity": 0.8,
          },
        });
      });

      // Center the map on the current step
      const currentStepIndCoordinates =
        selectedSteps[currentStepInd].geometry.coordinates;

      const middleIndex = Math.floor(currentStepIndCoordinates.length / 2);
      const [lng, lat] = currentStepIndCoordinates[middleIndex];
      // coords of current step
      map.current.flyTo({
        center: [lng, lat],
        essential: true, // This animation is considered essential with respect to prefers-reduced-motion
        zoom: 14,
      });
    }
  }, [currentStepInd, selectedSteps]);

  const handleNextStep = () => {
    setcurrentStepInd((prevStep) => (prevStep + 1) % selectedSteps.length);
  };

  const handlePrevStep = () => {
    setcurrentStepInd(
      (prevStep) => (prevStep - 1 + selectedSteps.length) % selectedSteps.length
    );
  };

  const handleRouteChange = (event) => {
    console.log("selected route number,", event.target.value);
    setcurrentStepInd(0); //back to the start
    setSelectedRouteInd(event.target.value);
  };

  const handleSelection = async (sug) => {
    console.log("is route analsysi", isRouteAnalysis);
    if (isRouteAnalysis) {
      setShowReport(false);
      if (valueUnderChange == 1) {
        if (marker1 != null) {
          marker1.remove();
        }
        // console.log("suggestion we are saeving", sug);

        setSuggestions([]);
        // const { features } = await autofill.retrieve(sug, {
        //   sessionToken: session,
        // });
        const features = await retrieveSuggestion(sug.mapbox_id, session);

        // setOriginObj(features)
        console.log("features collected", features?.geometry.coordinates);
        let x = features;
        setSelectedSuggestion(x);
        setOriginObj(x);
        map?.current?.flyTo({
          center: features.geometry.coordinates,
          zoom: 15,
        });
        // Marker is placed at analysed location
        var marker1 = new mapboxgl.Marker()
          .setLngLat([
            features.geometry.coordinates[0],
            features.geometry.coordinates[1],
          ])
          .addTo(map.current);

        setValue(sug.name + ", " + sug.place_formatted);
      }
      if (valueUnderChange == 2) {
        if (marker2 != null) {
          marker2.remove();
        }
        //if we are changing destination
        // console.log("suggestion we are saeving", sug);

        setSuggestions([]);
        // const { features } = await autofill.retrieve(sug, {
        //   sessionToken: session,
        // });
        const features = await retrieveSuggestion(sug.mapbox_id, session);

        // setDestObj(features)
        console.log("features collected", features?.geometry.coordinates);
        let x = features;
        setSelectedSuggestion2(x); // destination selection
        setDestObj(x);
        map?.current?.flyTo({
          center: features.geometry.coordinates,
          zoom: 15,
        });
        // Marker is placed at analysed location
        var marker2 = new mapboxgl.Marker()
          .setLngLat([
            features.geometry.coordinates[0],
            features.geometry.coordinates[1],
          ])
          .addTo(map.current);

        setValue2(sug.name + ", " + sug.place_formatted);
      }
    } else {
      // console.log("is route analysis", isRouteAnalysis);
      // console.log("suggestion we are saeving", sug);
      setIsLoading(!isLoading);
      if (marker != null) {
        marker.remove();
      }
      setSuggestions([]);
      // const { features } = await autofill.retrieve(sug, {
      //   sessionToken: session,
      // });
      const features = await retrieveSuggestion(sug.mapbox_id, session);

      console.log("features collected: ", features);
      let x = features;
      setSelectedSuggestion(x);
      setOriginObj(x);
      map?.current?.flyTo({
        center: features.geometry.coordinates,
        zoom: 15,
      });
      // Marker is placed at analysed location
      var marker = new mapboxgl.Marker()
        .setLngLat([
          features.geometry.coordinates[0], //longitude
          features.geometry.coordinates[1], //latitude
        ])
        .addTo(map.current);
      // console.log("the first value is, ", features[0].geometry.coordinates[0]);
      const score = await getSafetyScore(
        features.geometry.coordinates[1],
        features.geometry.coordinates[0]
      );

      console.log("the socre produced: ", score.score.toFixed(1));
      setSafetyScore(score.score.toFixed(1));

      setValue(sug.name + ", " + sug.place_formatted);
      setShowReport(true);
      console.log("done");
      setIsLoading(false);
      console.log("changed");
    }
  };
  //functino for when we select a suggestino
  // useEffect(() => {}, [isLoading]);

  return (
    <div>
      <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
      <div className="suggestionsContainer">
        <div className="outerInputContainer">
          <div className="inputContainer">
            {/* <AddressAutofill accessToken={accessToken}> */}
            <div className="inner-container">
              <Input
                allowClear
                style={{ minWidth: 400 }}
                autoComplete={true}
                size="large"
                placeholder="Starting location "
                value={value}
                onChange={inputChange}
              />
            </div>

            {isRouteAnalysis ? (
              <Input
                allowClear
                style={{ minwidth: 350, marginTop: 10 }}
                autoComplete={true}
                size="large"
                placeholder="Destination here"
                value={value2}
                onChange={inputChangeValue2}
              />
            ) : null}

            {/* </AddressAutofill> */}
            {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={top100Films}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Movie" />}
          onSelect={() => getSuggestions()}
          /> */}
          </div>

          <div
            style={{
              // justifyContent: "center",
              // alignItems: "center",
              // backgroundColor: "green",
              width: 50,
            }}
          >
            {!isRouteAnalysis ? (
              <Button
                onClick={() => handlePlusMinusBtnPress()}
                style={{
                  alignSelf: "center",
                  width: 40,
                  height: 40,
                  // marginLeft: 5,
                  // marginTop: 5,
                  // height: 50,
                }}
                // type="primary"
                shape="circle"
                icon={<PlusCircleTwoTone style={{ fontSize: 30 }} />}
              />
            ) : (
              <Button
                onClick={() => handlePlusMinusBtnPress()}
                style={{
                  alignSelf: "center",
                  width: 40,
                  height: 40,
                  // marginTop: 5,
                  // height: 50,
                }}
                // type="primary"
                shape="circle"
                icon={<MinusCircleTwoTone style={{ fontSize: 30 }} />}
              />
            )}
          </div>
        </div>
        {isRouteAnalysis ? (
          <div>
            <Button
              type="primary"
              style={{ marginTop: 10, alignSelf: "flex-end", marginLeft: 0 }}
              icon={<SearchOutlined />}
              onClick={() => analyseAndDisplayRoutes()}
            >
              Search
            </Button>
          </div>
        ) : null}

<div
  style={{
    maxHeight: '50vh',
    overflowY: 'auto', 
  }}
>
  {suggestions?.map((suggestion) => {
    return (
      <Card
        sx={{
          textAlign: 'left',
          minHeight: '2rem',
          paddingLeft: 1,
        }}
        key={suggestion.id} 
      >
        <CardActionArea onClick={() => handleSelection(suggestion)}>
          <CardContent>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                fontSize: 12,
                fontWeight: '700',
                marginLeft: -2,
              }}
            >
              {suggestion.name}
            </Typography>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                color: 'grey',
                fontSize: 9,
                fontWeight: '600',
                marginLeft: -2,
              }}
            >
              {suggestion.place_formatted}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  })}
</div>
      </div>

      {showReport && !isRouteAnalysis ? (
        <div className="report-container">
          <button className="chevronButton" onClick={toggleMinimize}>
            {isMinimized ? "▼" : "▲"}
          </button>
          <div
            className={`locationReportContainer ${
              isMinimized ? "minimized" : ""
            }`}
          >
            <h2 className="title">Safety Report</h2>
            <div className="content">
              <div className="info-row">
                <span className="label">Location Analysed:</span>
                <span className="value">
                  {selectedSuggestion?.properties?.full_address ||
                    selectedSuggestion?.place_name}
                </span>
              </div>
              <div className="info-row">
                <span className="label">Latitude:</span>
                <span className="value">
                  {selectedSuggestion?.geometry?.coordinates[0]}
                </span>
              </div>
              <div className="info-row">
                <span className="label">Longitude:</span>
                <span className="value">
                  {selectedSuggestion?.geometry?.coordinates[1]}
                </span>
              </div>
              <div className="info-row">
                <span className="label">Threat severity:</span>
                {/* <span className="value">{safetyScore}/10</span> */}
                <span className="value">Coming Soon....</span>
              </div>
              <div className="info-row">
                <span className="label">Threat Frequency:</span>
                {/* <span className="value">{safetyScore}/10</span> */}
                <span className="value">Coming Soon....</span>
              </div>
              {/* <div className="info-row">
              <span className="label">Crime Frequency:</span>
              <span className="value">
                0.4 crimes per day are reported from this location
              </span>
            </div> */}
              <div className="info-row">
                <span className="label">Recommendation:</span>
                {safetyScore < 6 ? (
                  <span className="value-red">Avoid</span>
                ) : safetyScore < 7.5 ? (
                  <span className="value-yellow">Be Wary</span>
                ) : (
                  <span className="value-green">
                    This area tends to be safe
                  </span>
                )}
              </div>
              <div className="info-row">
                <span className="label">Emergency Number:</span>
                <span className="value">999</span>
              </div>
              {/* Add more fields as necessary */}
              <div className="info-row">
                Powered By MET Police London reports
              </div>
              {/* add more fields as necessary */}
            </div>
          </div>
        </div>
      ) : null}
      {showReport && isRouteAnalysis ? (
        //this is the code for the route display
        <div className="report-container">
          <div
            className={`locationReportContainer ${
              isMinimized ? "minimized" : ""
            }`}
          >
            <div className="header">
              <h2 className="title">Safety Report</h2>
              {/* <button className="chevronButton" onClick={toggleMinimize}>
                {isMinimized ? "▼" : "▲"}
              </button> */}
            </div>
            <div className="content">
              <div className="info-row">
                <span className="label">Departure:</span>
                <span className="value">
                  {selectedSuggestion?.properties?.full_address ||
                    selectedSuggestion?.place_name}
                </span>
              </div>

              <div className="info-row">
                <span className="label">Destination:</span>
                <span className="value">
                  {selectedSuggestion2?.properties?.full_address}
                </span>
              </div>

              <div className="info-row">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="route-select-label">Route</InputLabel>
                  <Select
                    labelId="route-select-label"
                    id="route-select"
                    value={selectedRouteInd}
                    defaultValue=""
                    label="Route"
                    onChange={handleRouteChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Array.from({ length: noRoutes }).map((_, index) => (
                      <MenuItem key={index} value={index}>{`Route ${
                        index + 1
                      }`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="info-row">
                <span className="label">Section:</span>
                <span className="value">
                  {isScoreloading
                    ? "Loading...."
                    : selectedSteps[currentStepInd]?.maneuver.instruction}
                </span>
              </div>

              <div className="info-row navigation-buttons">
                <button onClick={handlePrevStep} className="route-button">
                  ←
                </button>
                <button onClick={handleNextStep} className="route-button">
                  →
                </button>
              </div>

              <div className="info-row">
                <span className="label">Section Safety:</span>
                <span className="value">
                  {isScoreloading
                    ? "Loading...."
                    : stepObjects[currentStepInd]?.score.toFixed(1)}
                </span>
              </div>

              <div className="info-row">
                <span className="label">Overall Safety:</span>
                <span className="value">
                  {stepObjects.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.score;
                  }, 0) / stepObjects.length
                    ? (
                        stepObjects.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.score;
                        }, 0) / stepObjects.length
                      ).toFixed(1)
                    : "Loading...."}
                </span>
              </div>

              <div className="info-row">
                <span className="label">Recommendation:</span>
                <span className="value-yellow">Stay Proactive</span>
              </div>

              <div className="info-row">
                <span className="label">Emergency:</span>
                <span className="value">999</span>
              </div>

              <div className="info-row">
                Powered By MET Police London reports
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div ref={mapContainer} className="map-container" />
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      ) : null}
    </div>
  );
}
