import "./App.css";
import HomeScreen from "./screens/HomeScreen";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ContactScreen from "./screens/ContactScreen";
import FeedbackScreen from "./screens/FeedbackScreen";
import ProfileScreen from "./screens/ProfileScreen";
import Navbar from "./navigation/Navbar";
import EleosScreen from "./screens/EleosScreen";
import { useEffect } from "react";
import ProtestScreen from "./screens/ProtestScreen";

function App() {
  // const getSuggestions = async () => {
  //   const response = await searchBoxCore.suggest("1600 pennsylvania ave nw", {
  //     sessionToken: session,
  //   });
  //   console.log(response);
  // };
  useEffect(() => {
    alert("PLEASE REMOVE ADBLOCKER - it crashes our system");
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
      </header>
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" index element={<HomeScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
            <Route path="/feedback" element={<FeedbackScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/eleos" element={<EleosScreen />} />
            <Route path="/protests" element={<ProtestScreen />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
