import { SessionToken } from "@mapbox/search-js-core";
const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
console.log("access token", accessToken);

export const getLocationSuggestions = async (val, sessionToken) => {
  console.log("session token: ", sessionToken.sessionToken.id);
  let temp = await val;
  const suggestUrl = "https://api.mapbox.com/search/searchbox/v1/suggest?";
  const query = encodeURI(`q=${temp}&`);
  const queryAccessToken = `access_token=${accessToken}&`;
  const querySessionToken = `session_token=${sessionToken.sessionToken.id}`;
  const queryMetaData =
    "&language=en&limit=10&types=country%2Cregion%2Cdistrict%2Cpostcode%2Clocality%2Cplace%2Cneighborhood%2Caddress%2Cpoi%2Cstreet%2Ccategory&proximity=-0.1276,51.5079&country=GB";

  const fullQuery =
    suggestUrl + query + queryAccessToken + querySessionToken + queryMetaData;
  console.log("fullQuery url", fullQuery);

  const response = await fetch(fullQuery);
  const json = await response.json();
  console.log("response", json.suggestions);
  return json.suggestions;
  //   console.log("api response", json);
};

export const retrieveSuggestion = async (id, sessionToken) => {
  const suggestUrl = "https://api.mapbox.com/search/searchbox/v1/retrieve/";
  const query = encodeURI(`${id}?`);
  const queryAccessToken = `access_token=${accessToken}`;
  const querySessionToken = `session_token=${sessionToken.sessionToken.id}&`;

  const fullQuery = suggestUrl + query + querySessionToken + queryAccessToken;
  // console.log("fullQuery url", fullQuery);

  const response = await fetch(fullQuery);
  const json = await response.json();
  //   console.log("response", json);
  return json.features[0];
  //   console.log("api response", json);
};

// export async function fetchDirections(origin, dest) {
//   const originLatLng = {
//     latitude: origin[1],
//     longitude: origin[0],
//   };

//   const destLatLng = {
//     latitude: dest[1],
//     longitude: dest[0],
//   };

//   const requestOptions = {
//     profile: this.props.type,
//     geometry: "polyline",
//   };

//   let res = null;
//   try {
//     res = await mapboxClient.getDirections(
//       [originLatLng, destLatLng],
//       requestOptions
//     );
//   } catch (e) {
//     console.log(e);
//   }

//   if (res !== null) {
//     const directions = res.entity.routes[0];
//     this.setState({ directions: directions });
//   }
// }
